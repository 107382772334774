:root {
  --sand: #C4A092;
  --white: #E0D5D3;
  --grey: #1E1D1D;
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right bottom, #fdfdfd, #eaeaea) fixed; */
  /* background: linear-gradient(to right bottom, #750b1c, #71173e, #5e2b57, #453a63, #314362) fixed; */
}

* {
  font-family: 'Montserrat', sans-serif;
}


h1 {
  font-family: 'Libre Caslon Text';
  font-weight: bold;
  text-align: center;
  font-style: italic;
}

h2 {
  font-family: 'Libre Caslon Text';
  font-style: italic;
}

.grey {
  background-color: #1E1D1DA0;
  color: var(--white);
}

ul, li {
  text-align: left;
}

.font-lc {
  font-family: 'Libre Caslon Text';
}

.font-sand {
  color: var(--sand);
}

.font-white {
  color: var(--white);
}