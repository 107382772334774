.App {
  text-align: center;
}

.app-container {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* background-color: #fafafa; */
}

.main-content {
  margin: 0 auto;
  flex-grow: 5;
  display: flex;
  flex-direction: column;
  /* background-color: var(--white); */
}

nav {
  min-height: min(10vh, 60px);
  /* max-height: min(10vh, 50px); */
  font-size: large;
  padding: 0.5em;
  position: relative;
  
}
a {
  text-decoration: underline;
}
a, .nav-link {
  color: var(--white);
  font-family: 'Libre Caslon Text';
}
footer a {
  color: var(--white);
}
section a {
  color: inherit;
}
nav a {
  text-decoration: none!important;
}

a:hover, a:active {
  color: var(--sand)!important;
}
.nav-link:hover, .nav-link:focus, .nav-link.active, .show>.nav-link {
  color: var(--sand)!important;
  text-decoration: none;
}

.navbar-toggler {
  border: none;
  color: var(--white);
}

.navbar-toggler:hover, .navbar-toggler:active {
  color: var(--sand);
}

.dropdown-item a {
  background-color: transparent;
  border: solid 1px transparent;
  text-decoration: none;
  display: block;
  color: #202020!important;
  width: 100%;
}

.dropdown-item a:active, .dropdown-item a:hover {
  background-color: transparent;
  border: solid 1px transparent;
  text-decoration: none;
  color: #303030!important;
}

.dropdown-item:active {
  background-color: #5172a810;
}

.dropdown-menu.show {
  -webkit-box-shadow: 5px 4px 13px -4px rgba(0,0,0,0.31); 
  box-shadow: 5px 4px 13px -4px rgba(0,0,0,0.31);
}

.nav-container {
  background-color: #1E1D1DA0;
  position: relative;
  z-index: 2;
  /* background-color: #20202070; */
  /* -webkit-box-shadow: 0px 4px 22px 5px rgba(0,0,0,0.31); 
  box-shadow: 0px 4px 22px 5px rgba(0,0,0,0.31); */
}

.nav-main {
  background-color: transparent!important;
}

section {
  display: grid;
  place-items: center;
  align-content: center;
  min-height: 45vh;
  color: #202020;
  /* color: var(--white); */
  flex-grow: 2;
  padding: 1rem;
  background-color: #E0D5D3E0;
}

.no-min-vh {
  min-height: 0;
}

.clamp-h {
  max-width: 900px;
}

.min-h {
  width: 900px;
}

@media screen and (max-width: 900px) {
  .min-h {
    width: 100%;
  }
}
  


.min-vh {
  min-height: 75vh;
}


@media (prefers-reduced-motion: no-preference) {

  .sect-hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
  }

  .hidden-left {
    transform: translateX(-30%);
  }

  .sect-show {
    opacity: 1;
    filter: blur(0);
    transition: all 0.6s;
  }

  .fade-in-out {
    animation: fade-in-out-an 15s;
    animation-iteration-count: infinite;
  }
}

@keyframes fade-in-out-an {
  0%, 3%, 97%, 100% {
    opacity: 0;
    filter: blur(5px);
  }
  8%, 92% {
    opacity: 1;
    filter: blur(0);
  }
}

.review-text {
  opacity: 0.8;
}

.reviews {
  background-color: #101010A0;
  padding: 15px;
  border-radius: 15px;
}

section > * {
}

footer {
  display: grid;
  place-items: center;
  align-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
  min-height: max(15vh, calc(25vh - 60px));
  color: #101010;
  background-color: #202020E0;
  margin-top: auto;
}
footer * {
  font-size: 13px;
  font-family: 'Libre Caslon Text';
}
footer i {
  font-size: 10px;
}

.carousel-control-next, .carousel-control-prev {
  width: 5%;
  min-width: 5%;
}
.review-item {
  position: relative;
  max-width: 85%;
  min-width: 85%;
  width: 85%;
  margin: 0 auto;
  min-height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-primary {
  background-color: var(--secondary);
  border-color: var(--secondary-dark);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: var(--secondary-light);
  border-color: var(--secondary-dark);
}

.footer-items {
  color: #fefefe;
  min-width: 95vw;
  max-width: 95vw;
}

.divider {
  opacity: 0.9;
  border-top: solid 5px #A63501;
}
.divider-thin {
  border-top: solid 1px #223442c0;
}

.color-primary {
  color: var(--primary)
}

.bgImg {
  position: fixed;
  width: 100%;
  height: auto;
  /* opacity: 0.5; */
}

.bwHead {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.map {
  min-width: 100%;
  padding: 0 10px 0 10px;
}